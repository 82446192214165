import React, { useEffect, useState, useRef } from 'react';
import chatGPT from '../../../public/assets/ChatGPT_Access.png';
import chatGPTwebp from '../../../public/assets/ChatGPT_Access.webp';
import chatPDF from '../../../public/assets/ChatPDF.png';
import chatPDFwebp from '../../../public/assets/ChatPDF.webp';
import check from '../../../public/assets/Check.png';
import checkwebp from '../../../public/assets/Check.webp';
import cross from '../../../public/assets/Cross.png';
import crosswebp from '../../../public/assets/Cross.webp';
import dreamPhoto from '../../../public/assets/Dreamphoto.png';
import dreamPhotowebp from '../../../public/assets/Dreamphoto.webp';
// import gpt4Pro from '../../../public/assets/GPT-4_Pro_Max.png';
// import gpt4Prowebp from '../../../public/assets/GPT-4_Pro_Max.webp';
import gpt4o from '../../../public/assets/GPT-4o_Access.png';
import gpt4owebp from '../../../public/assets/GPT-4o_Access.webp';
import translate from '../../../public/assets/Translate_AI.png';
import translatewebp from '../../../public/assets/Translate_AI.webp';
// import unlimited from '../../../public/assets/Unlimited.png';
// import unlimitedwebp from '../../../public/assets/Unlimited.webp';
import unlimitedNo from '../../../public/assets/Unlimited-nooutline.png';
import unlimitedNowebp from '../../../public/assets/Unlimited-nooutline.webp';
import whatsApp from '../../../public/assets/WhatsApp.png';
import whatsAppwebp from '../../../public/assets/WhatsApp.webp';
import './pricing_table.css';

const PricingTable = () => {
  const [isHidden, setIsHidden] = useState(false);
  const bottomRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsHidden(window.innerHeight <= 800);
    };

    handleResize(); // Check on initial load
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const scrollToTop = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to top
    }
  };


  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (!container) return;

      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const containerHeight = container.clientHeight;

      if (scrollTop + containerHeight >= scrollHeight) {
        setIsAtBottom(true);
      } else {
        setIsAtBottom(false);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div className="hidden md:flex justify-center flex-[3] bg-black rounded-l-2xl	">
      <div ref={containerRef} className={` pt-8 min-h-800:pt-[4.5rem] min-h-900:!pt-0 grid lg:min-h-800:flex min-h-900:flex min-w-1720:pt-[6rem] min-w-1720:flex min-w-1720:pl-16 custom-scrollbar flex-col flex-grow justify-center max-w-[1024px] pr-4 pl-10`}>
        <div className="inline-block">
          <span className="p-2 md:inline-flex w-[40%] md:w-[60%] min-w-1720:w-[65%]"></span>
          <span className="p-2 text-white text-lg font-bold md:inline-flex md:w-[65px] xl:w-[17%] min-w-1720:w-[14%] justify-center text-center">FREE</span>
          <span className="p-2 text-[#21B57F] text-lg font-bold md:inline-flex md:w-[65px] xl:w-[17%] min-w-1720:w-[14%] justify-center text-center">PRO</span>
          {/* <span className="p-2 text-[#21B57F] font-bold md:inline-flex md:w-[100px] lg:w-[140px] xl:w-[180px] justify-center text-center">PRO MAX</span> */}
        </div>
        <div className="block md:pb-4 lg:pb-8 max-h-md:pb-2">
          <div className="flex flex-col bg-black w-auto inline-flex z-10 text-[#21B57F] py-2 mb-6 max-h-md:py-1 pr-8 pl-2 relative font-bold">ChatBot Pro</div>
          <hr className='relative w-[93%] mt-[-44px] max-h-md:mt-[-17px] border-1 border-gray-400'></hr>
        </div>
        <table className="min-w-full block md:table">
          <tbody className="md:table-row-group">
            <tr className=" md:table-row">
              <td className="p-2 text-left md:inline-flex w-[40%] md:w-[60%] min-w-1720:w-[65%] text-white font-bold text-xl">
                <div className='pr-2 inline-block min-w-[40px]'>
                  <picture>
                    <source type="image/webp" srcSet={chatGPTwebp} width="30" height="30" className="mr chatGPT" />
                    <img src={chatGPT} alt="chatGPT" width="30" height="30" className="mr chatGPT"/>
                  </picture>
                </div>
                <div className='inline-block text-md md:text-lg lg:text-xl max-h-md:text-md'>
                  Access ChatGPT<br/><span className='block text-gray-500 text-sm  max-h-md:text-sm md:text-md xl:text-lg font-normal'>Chat with GPT-3.5, GPT-4o</span>
                </div>
              </td>
              <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={checkwebp} width="30" height="30" className="mx-auto check" />
                  <img src={check} alt="check" width="30" height="30" className="mx-auto check"/>
                </picture>
              </td>
              <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={checkwebp} width="30" height="30" className="mx-auto check" />
                  <img src={check} alt="check" width="30" height="30" className="mx-auto check"/>
                </picture>
              </td>
              {/* <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={unlimitedwebp} width="30" height="30" className="mx-auto unlimited" />
                  <img src={unlimited} alt="unlimited" width="30" height="30" className="mx-auto unlimited"/>
                </picture>
              </td> */}
            </tr>
            <tr className=" md:table-row">
              <td className="p-2 text-left md:inline-flex w-[40%] md:w-[60%] min-w-1720:w-[65%] text-white font-bold text-xl">
                <div className='pr-2 inline-block min-w-[40px]'>
                  <picture>
                    <source type="image/webp" srcSet={gpt4owebp} width="30" height="30" className="mr gpt4o" />
                    <img src={gpt4o} alt="gpt4o" width="30" height="30" className="mr gpt4o"/>
                  </picture>
                </div>
                <div className='inline-block text-md md:text-lg lg:text-xl'>
                  <div className='flex flex-col items-start text-md md:text-lg lg:text-xl'>
                    <div className='flex flex-wrap items-start min-w-1210:flex-row min-w-1440:flex-wrap gap-3'>
                      <span className='min-w-max max-h-md:text-md'>Advanced Models</span>
                      <div className="bg-[#144634] rounded-full px-2 ml-0 1230px:ml-3 text-base mt-2 max-h-md:text-sm md:mt-0">New</div>
                    </div>
                    <span className='block text-gray-500 text-sm md:text-md xl:text-lg font-normal mt-1 max-h-md:text-sm'>
                      Gemini Pro, Advanced Tools
                    </span>
                  </div>
                </div>
              </td>
              <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={crosswebp} width="30" height="30" className="mx-auto cross" />
                  <img src={cross} alt="cross" width="30" height="30" className="mx-auto cross"/>
                </picture>
              </td>
              <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={checkwebp} width="30" height="30" className="mx-auto check" />
                  <img src={check} alt="check" width="30" height="30" className="mx-auto check"/>
                </picture>
              </td>
              {/* <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={unlimitedwebp} width="30" height="30" className="mx-auto unlimited" />
                  <img src={unlimited} alt="unlimited" width="30" height="30" className="mx-auto unlimited"/>
                </picture>
              </td> */}
            </tr>
            {/* <tr className=" md:table-row">
              <td className="p-2 text-left md:inline-flex w-[40%] md:w-[60%] min-w-1720:w-[65%] text-white font-bold text-xl">
                <div className='pr-2 inline-block min-w-[40px]'>
                  <picture>
                    <source type="image/webp" srcSet={gpt4Prowebp} width="30" height="30" className="mr gpt4Pro" />
                    <img src={gpt4Pro} alt="gpt4Pro" width="30" height="30" className="mr gpt4Pro"/>
                  </picture>
                </div>
                <div className='inline-block text-md md:text-lg lg:text-xl'>
                  <span className='text-[#21B57F]'>Pro Max</span><br/><span className='block text-gray-500 text-sm md:text-md xl:text-lg font-normal'>Hold longer, advanced conversations</span>
                </div>
              </td>
              <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={crosswebp} width="30" height="30" className="mx-auto cross" />
                  <img src={cross} alt="cross" width="30" height="30" className="mx-auto cross"/>
                </picture>
              </td>
              <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={crosswebp} width="30" height="30" className="mx-auto cross" />
                  <img src={cross} alt="cross" width="30" height="30" className="mx-auto cross"/>
                </picture>
              </td>
              {/* <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={unlimitedwebp} width="30" height="30" className="mx-auto unlimited" />
                  <img src={unlimited} alt="unlimited" width="30" height="30" className="mx-auto unlimited"/>
                </picture>
              </td> 
            </tr> */}
          </tbody>
        </table>
        <div className="block md:pb-4 lg:pb-8 max-h-md:pb-2">
        <div className="flex flex-col bg-black w-auto inline-flex z-10 text-[#21B57F] py-2 mb-6 max-h-md:py-1 pr-8 pl-2 relative font-bold">Other AI Apps</div>
          <hr className='relative w-[93%] mt-[-44px] max-h-md:mt-[-17px] border-1 border-gray-400'></hr>
        </div>
        <table className="min-w-full block md:table">
          <tbody className="md:table-row-group">
            <tr className=" md:table-row">
              <td className="p-2 text-left md:inline-flex w-[40%] md:w-[60%] min-w-1720:w-[65%] text-white font-bold text-xl">
                <div className='pr-2 inline-block min-w-[40px]'>
                  <picture>
                    <source type="image/webp" srcSet={chatPDFwebp} width="30" height="30" className="mr chatPDF" />
                    <img src={chatPDF} alt="chatPDF" width="30" height="30" className="mr chatPDF"/>
                  </picture>
                </div>
                <div className='inline-block text-md md:text-lg lg:text-xl max-h-md:text-md'>
                  ChatPDF<br/><span className='block text-gray-500 text-sm max-h-md:text-sm md:text-md xl:text-lg font-normal'>Summarize and chat with your documents</span>
                </div>
              </td>
              <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={checkwebp} width="30" height="30" className="mx-auto check" />
                  <img src={check} alt="check" width="30" height="30" className="mx-auto check"/>
                </picture>
              </td>
              <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={unlimitedNowebp} width="30" height="30" className="mx-auto unlimitedNo" />
                  <img src={unlimitedNo} alt="unlimitedNoOutline" width="30" height="30" className="mx-auto unlimitedNo"/>
                </picture>
              </td>
              {/* <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={unlimitedwebp} width="30" height="30" className="mx-auto unlimited" />
                  <img src={unlimited} alt="unlimited" width="30" height="30" className="mx-auto unlimited"/>
                </picture>
              </td> */}
            </tr>
            <tr className=" md:table-row">
              <td className="p-2 text-left md:inline-flex w-[40%] md:w-[60%] min-w-1720:w-[65%] text-white font-bold text-xl">
                <div className='pr-2 inline-block min-w-[40px]'>
                  <picture>
                    <source type="image/webp" srcSet={dreamPhotowebp} width="30" height="30" className="mr dreamPhoto" />
                    <img src={dreamPhoto} alt="dreamPhoto" width="30" height="30" className="mr dreamPhoto"/>
                  </picture>
                </div>
                <div className='inline-block text-md md:text-lg lg:text-xl max-h-md:text-md'>
                  Image Generation<br/><span className='block text-gray-500 text-sm max-h-md:text-sm md:text-md xl:text-lg font-normal'>Unlock your creativity and generate images</span>
                </div>
              </td>
              <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={checkwebp} width="30" height="30" className="mx-auto check" />
                  <img src={check} alt="check" width="30" height="30" className="mx-auto check"/>
                </picture>
              </td>
              <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={unlimitedNowebp} width="30" height="30" className="mx-auto unlimitedNo" />
                  <img src={unlimitedNo} alt="unlimitedNoOutline" width="30" height="30" className="mx-auto unlimitedNo"/>
                </picture>
              </td>
              {/* <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={unlimitedwebp} width="30" height="30" className="mx-auto unlimited" />
                  <img src={unlimited} alt="unlimited" width="30" height="30" className="mx-auto unlimited"/>
                </picture>
              </td> */}
            </tr>
            <tr className=" md:table-row">
              <td className="p-2 text-left md:inline-flex w-[40%] md:w-[60%] min-w-1720:w-[65%] text-white font-bold text-xl">
                <div className='pr-2 inline-block min-w-[40px]'>
                  <picture>
                    <source type="image/webp" srcSet={translatewebp} width="30" height="30" className="mr translate" />
                    <img src={translate} alt="translate" width="30" height="30" className="mr translate"/>
                  </picture>
                </div>
                <div className='inline-block text-md md:text-lg lg:text-xl max-h-md:text-md'>
                  Translation<br/><span className='block text-gray-500 text-sm max-h-md:text-sm md:text-md xl:text-lg font-normal'>Translate text with AI</span>
                </div>
              </td>
              <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={checkwebp} width="30" height="30" className="mx-auto check" />
                  <img src={check} alt="check" width="30" height="30" className="mx-auto check"/>
                </picture>
              </td>
              <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={unlimitedNowebp} width="30" height="30" className="mx-auto unlimitedNo" />
                  <img src={unlimitedNo} alt="unlimitedNoOutline" width="30" height="30" className="mx-auto unlimitedNo"/>
                </picture>
              </td>
              {/* <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={unlimitedwebp} width="30" height="30" className="mx-auto unlimited" />
                  <img src={unlimited} alt="unlimited" width="30" height="30" className="mx-auto unlimited"/>
                </picture>
              </td> */}
            </tr>

            <tr className=" md:table-row">
              <td className="p-2 text-left md:inline-flex w-[40%] md:w-[60%] min-w-1720:w-[65%] text-white font-bold text-xl">
                <div className='pr-2 inline-block min-w-[40px]'>
                  <picture>
                    <source type="image/webp" srcSet={whatsAppwebp} width="30" height="30" className="mr whatsApp" />
                    <img src={whatsApp} alt="whatsApp" width="30" height="30" className="mr whatsApp"/>
                  </picture>
                </div>
                <div className='flex flex-col items-start text-md md:text-lg lg:text-xl'>
                  {/* <div className='flex flex-col items-start min-w-1440:flex-row gap-3'> */}
                  <div className='flex flex-wrap items-start gap-3'>
                    <div className='min-w-max max-h-md:text-md'>Chat in WhatsApp</div>
                    <div className="bg-[#177755] rounded-full px-2 ml-0 1230px:ml-3 max-h-md:text-sm text-base mt-2 md:mt-0 w-[126px]">Coming Soon</div>
                  </div>
                  <span className='block text-gray-500 text-sm md:text-md xl:text-lg font-normal mt-1 max-h-md:text-sm'>
                    Use Chatbot App within WhatsApp
                  </span>
                </div>
              </td>
              <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={crosswebp} width="30" height="30" className="mx-auto cross" />
                  <img src={cross} alt="cross" width="30" height="30" className="mx-auto cross"/>
                </picture>
              </td>
              <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={checkwebp} width="30" height="30" className="mx-auto check" />
                  <img src={check} alt="check" width="30" height="30" className="mx-auto check"/>
                </picture>
              </td>
              {/* <td className="p-2 md:inline-flex justify-center items-center md:w-[65px] xl:w-[17%] min-w-1720:w-[14%]">
                <picture>
                  <source type="image/webp" srcSet={unlimitedwebp} width="30" height="30" className="mx-auto unlimited" />
                  <img src={unlimited} alt="unlimited" width="30" height="30" className="mx-auto unlimited"/>
                </picture>
              </td> */}
            </tr>
          </tbody>
        </table>
        <div ref={bottomRef} className="h-0"></div>
      </div>
      
      <button
          className={`absolute bottom-[15px] right-[335px] w-10 h-10 rounded-full hidden exact-h-600:flex items-center justify-center bg-[#ffffff0a] text-[#f0f8ff78] shadow-lg transition-transform transform hover:scale-105`}
          onClick={isAtBottom ? scrollToTop : scrollToBottom}
        >
          {isAtBottom ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 animate-bounce2">
              <path fill-rule="evenodd" d="M10 17a.75.75 0 0 1-.75-.75V5.612L5.29 9.77a.75.75 0 0 1-1.08-1.04l5.25-5.5a.75.75 0 0 1 1.08 0l5.25 5.5a.75.75 0 1 1-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0 1 10 17Z" clip-rule="evenodd" />
            </svg>
          )
          : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 animate-bounce2">
              <path fill-rule="evenodd" d="M10 3a.75.75 0 0 1 .75.75v10.638l3.96-4.158a.75.75 0 1 1 1.08 1.04l-5.25 5.5a.75.75 0 0 1-1.08 0l-5.25-5.5a.75.75 0 1 1 1.08-1.04l3.96 4.158V3.75A.75.75 0 0 1 10 3Z" clip-rule="evenodd" />
            </svg>
          )}
        </button>
    </div>
  );
};

export default PricingTable;
