import { usePricingPopup, subscriptionType, setWindowValue, getWindowValue } from '../helper/store';
import axios from 'axios';
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';

import { IoMdClose } from 'react-icons/io';
import { cn, cookie } from '~/utils';
import PricingPopupCard from './PricingPopupCard';
import PricingTable from './pricing_table.jsx';
import jewel from '../../../public/assets/chatbot-ask-logo-icon.png';
import ccImages from '../../../public/assets/cc_v5.png';
import ctaArrow from '../../../public/assets/cta_arrow.svg';

import useEmblaCarousel from 'embla-carousel-react';

const VITE_REGISTER_URL_START =
  import.meta.env.VITE_REGISTER_URL_START ||
  'https://chatbotask.ai/register?ppg=51&reg_google=on&from_chatapp=on';

const VITE_REGISTER_URL_CHATAPP =
  import.meta.env.VITE_REGISTER_URL_CHATAPP ||
  'https://chatapp.chatbotask.ai/register?ppg=51&reg_google=on&from_chatapp=on';

const VITE_PAYMENT_PAGE_URL =
  import.meta.env.VITE_PAYMENT_PAGE_URL || 'https://chatbotask.ai/pay?ppg=51&from_chatapp=on';

const upgradeUrl = import.meta.env.VITE_UPGRADE_URL || 'https://chatbotask.ai/upgrade';

export default function PricingPopup() {
  const {
    type: sType,
    payment_interval: subscriptionInterval,
    isEnterprise,
    setType,
    setPaymentInterval,
    isProMax,
    isPro,
  } = subscriptionType((state) => state);
  const subscription: string = (sType || '').toLowerCase();
  const pState = usePricingPopup((state) => state);
  const [open, setOpen] = useState<boolean>(false);

  const radioRef = useRef<HTMLInputElement>(null);

  const handleDivClick = (planId) => {
    setSelectedPlan(planId)
    if (radioRef.current && planId !== selectedPlan) {
      radioRef.current.checked = true;
    }
  };

  const isSelectedPlan = (planId) => {
    return selectedPlan === '109' || selectedPlan === planId;
  };

  useEffect(() => {
    if (pState.pricingData.length === 0) {
      (async () => {
        const ppg = import.meta.env.VITE_PPG
          ? import.meta.env.VITE_PPG
          : isEnterprise
          ? '51'
          : '72';
        try {
          const response = await axios.post(
            `${import.meta.env.VITE_HOST_START}/api/get-pricing`,
            { ppg, from_upgrade: 1 },
            { headers: { 'content-type': 'application/x-www-form-urlencoded' } },
          );
          const output = response.data;
          if (output.success) {
            pState.setPricingData(output.data);
          }
        } catch (error) {
          console.log('error', error);
        }
      })();
    }
  }, [pState.pricingData]);

  const getCookieValue = (key: string) => {
    const keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? decodeURIComponent(keyValue[2]) : null;
  };

  const redirectToPaymentPage = useCallback((plan_id: number) => {
    setWindowValue("mxadid", cookie.getValue('adid'));
    setWindowValue("mxemailid", cookie.getValue('emailid'));
    setWindowValue("mxkeyword", cookie.getValue('keyword'));
    setWindowValue("mxpmt", cookie.getValue('pmt') || 'pay2');
    setWindowValue("mxppg", cookie.getValue('ppg') || 72);
    setWindowValue("mxPanelTrack", cookie.getValue('mixPanelTrack') || getWindowValue("mxPanelTrack"));
    setWindowValue("mxPanelClickLocation", cookie.getValue('mixPanelClickLocation') || getWindowValue("mxPanelClickLocation"));
    const redirectRegistrationPage = (plan_id: number) => {
      const is_chatapp = getCookieValue('chatapp');

      if (document.referrer.includes('chatapp') || is_chatapp === 'on') {
        window.location.href = VITE_REGISTER_URL_CHATAPP + '&pricing=' + plan_id;
      } else {
        window.location.href = VITE_REGISTER_URL_START + '&pricing=' + plan_id;
      }
    };

    setTimeout(function () {
      if (typeof window.AIPRO_USER !== 'undefined' && window.AIPRO_USER && window.AIPRO_USER.email) {
        window.location.href = VITE_PAYMENT_PAGE_URL + '&pricing=' + plan_id;
      } else {
        redirectRegistrationPage(plan_id);
      }
    }, 2000);
  }, []);

  useEffect(() => {
    if (pState.showPricingPopup) {
      const mxPanelTrack: any = getWindowValue("mxPanelTrack");
      const model = cookie.getValue('mixPanelTrack')?.toLowerCase() || mxPanelTrack?.toLowerCase() || '';

      if (
        typeof window.AIPRO_USER !== 'undefined' &&
        window.AIPRO_USER &&
        window.AIPRO_USER.subscription_type
      ) {
        const subscription_type = window.AIPRO_USER.subscription_type;
        const payment_interval = window.AIPRO_USER.payment_interval;

        setType(subscription_type);
        setPaymentInterval(payment_interval);

        if (
          (isPro && !isProMax && model === 'gpt-4-pro-max') ||
          (subscription_type.toLocaleLowerCase() === 'basic' && !model?.includes('gpt-3')) ||
          (!isProMax && model?.includes('claude')) ||
          (!isProMax && model?.includes('dalle'))
        ) {
          if (upgradeUrl.includes('?')) {
            window.open(upgradeUrl + (isPro ? '&vjicon=1' : ''), '_blank');
          } else {
            window.open(upgradeUrl + (isPro ? '?vjicon=1' : ''), '_blank');
          }
          pState.setShowPricingPopup(false);
          return;
        }
      }

      if (document.body.style.pointerEvents === 'none') {
        document.body.style.pointerEvents = 'auto';
      }

      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [pState, isPro, isProMax, setPaymentInterval, setType]);

  var ppgSet = '72';
  var topPlanId = '107';
  var mostPopPlanId = '108';
  var defaultPlanId = '109';
  const [selectedPlan, setSelectedPlan] = useState('109');

  const cardComponents = useMemo(() => {
    const components = [
      {
        label: 'FREE',
        display_txt1: '$0/Month',
        price: '0',
        plan_type: '',
        payment_interval: '',
      },
      ...pState.pricingData,
    ]
      .map((data, idx) => {
        const currentPlan =
          subscription?.toLowerCase() === data.plan_type?.toLowerCase() &&
          subscriptionInterval?.toLowerCase() === data.payment_interval?.toLowerCase();

        const isMonthly = data.payment_interval.toLowerCase() === 'monthly';
    const isNotCurrentSubscription =
      subscription.toLowerCase() !== data.plan_type.toLowerCase();
    const isCurrentSubscription =
      subscription.toLowerCase() === data.plan_type.toLowerCase() &&
      subscriptionInterval.toLowerCase() === data.payment_interval.toLowerCase();

    if (
          (isMonthly && (isNotCurrentSubscription || isCurrentSubscription)) ||
      (subscription === '' && data.payment_interval === '')
    ) {
      return (
        <PricingPopupCard
          key={idx}
          currentPlan={currentPlan}
          label={data.label}
          price={Number(data.price)}
          plan_type={data.plan_type}
          onClick={data.plan_id ? () => redirectToPaymentPage(data.plan_id) : undefined}
          display_txt2={data.display_txt2}
        />
      );
    }
      })
      .filter((c) => c !== undefined);
  return components;
}, [pState.pricingData, redirectToPaymentPage, subscription, subscriptionInterval]);

  const [emblaRef, emblaApi] = useEmblaCarousel({ align: 'center', startIndex: 1 });
  const [selectedIndex, setSelectedIndex] = useState(1);

  useEffect(() => {
    if (emblaApi) {
      const onSelect = () => {
        setSelectedIndex(emblaApi.selectedScrollSnap());
      };
      emblaApi.on('select', onSelect);
    }
  }, [emblaApi]);

  if (!pState.pricingData.length) {
    return null;
  }

  return (
    <div
      id="pricing-modal"
      className={cn(
        'relative flex max-h-full items-center justify-center backdrop-blur-sm transition-opacity duration-200 ease-in md:flex-row',
        open ? 'opacity-100' : 'pointer-events-none opacity-0 delay-200'
        // ',
      )}
      onClick={(e) => {
        if ((e.target as HTMLDivElement).id === 'pricing-modal') {
          pState.setShowPricingPopup(true);
        }
      }}
    >
      <div className="absolute right-0 top-0 mr-5 mt-5">
        <IoMdClose
          className="cursor-pointer text-2xl text-slate-200"
          onClick={() => {
            pState.setShowPricingPopup(false);
          }}
        />
      </div>

      <section className="w-full h-full ">
        <div className="w-full h-full flex justify-center">
          
          <div className="flex md:min-w-[768px] justify-center w-[90%] md:w-[68%] h-[85%] bg-white rounded-2xl my-auto relative">

          <PricingTable></PricingTable>
          <div className="flex justify-center w-full md:w-[40%] h-full relative">
          
            
            <div className="justify-center w-full m-auto h-full overflow-y-scroll min-h-900:h-auto max-h-full">
                    <img
                    src={jewel}
                    alt="Logo"
                    className="mt-6 min-w-500:mt-8 mb-4 min-w-500:min-h-md:mt-12 min-h-md:mb-8 mx-auto flex h-auto w-[60px] min-w-1510:mt-8"
                    />
                    
                    <h1 className="text-[1.75rem] md:text-[2rem] min-w-1720:text-[2.5rem] font-bold text-center mb-4 min-h-[40px] leading-tight max-w-800:max-h-sm:text-[2rem] ">
                      Upgrade to<br/><span className="text-[#177755]">Chatbot Pro</span>
                    </h1>
            
                    <p className="text-center mb-4 text-[1rem] md:text-[1.125rem] xl:max-h-800:mb-5 max-w-800:max-h-sm:text-base max-w-800:max-h-sm:mb-5">Access the most powerful ChatBot</p>
            
                    <div className="m-auto pl-2 md:pl-0 w-[90%] md:w-[80%] max-w-[400px] block">
                      {pState.pricingData.map((pricingData) => {
                        return(
                        <div 
                        onClick={() => handleDivClick(pricingData.plan_id)}
                        key = {pricingData.plan_id}
                        className={cn(
                          `mt-auto flex flex-col items-center rounded-[1rem] min-w-[245px] border border-[#8b8b8c] bg-white relative border-2 ${pricingData.plan_id === mostPopPlanId ? 'h-[84px] ' : 'h-[76px] '}`,
                          {
                            'border-black': selectedPlan === pricingData.plan_id,
                          },
                          {
                            'mb-4': defaultPlanId !== pricingData.plan_id,
                          },
                        )}
                      >
                        {(() => {
                              if (pricingData.plan_id == mostPopPlanId) {
                                return  (<div className={`absolute text-white text-[9px] top-[-9px] right-[9%] h-[18px] w-[56px] pt-[3px] pl-[6px] rounded-[1rem] ${mostPopPlanId === selectedPlan ? 'bg-[#dc2728]' : 'bg-[#ee9394]'}`}>SAVE 33%</div>);
                              } else if (pricingData.plan_id == defaultPlanId) {
                                return  (<div className={`absolute text-white text-[9px] top-[-9px] right-[9%] h-[18px] w-[56px] pt-[3px] pl-[6px] rounded-[1rem] ${defaultPlanId === selectedPlan ? 'bg-[#dc2728]' : 'bg-[#ee9394]'}`}>SAVE 67%</div>);
                              }
                            })()}
                      
                        {(() => {
                              if (pricingData.plan_id === mostPopPlanId) {
                                return  (<div className={`text-white text-[10px] h-[25px] w-full text-center pt-1 rounded-t-[0.75rem] ${mostPopPlanId === selectedPlan ? 'bg-black' : 'bg-[#8b8b8c]'}`}>MOST POPULAR</div>);
                              } 
                            })()}
                        
                        <div className="flex w-full relative px-3 my-auto">
                          <div className="min-w-[115px] gap-3 flex left-0">
                            <input 
                            className="bg-[#177755] border-[#177755] w-[22px] accent-[#177755] my-auto min-w-500:m-0"
                            type="radio" 
                            name="plan" 
                            ref={radioRef}
                            value={pricingData.plan_id}
                            checked={isSelectedPlan(pricingData.plan_id)}
                            onChange={() => handleDivClick(pricingData.plan_id)}
                            ></input>

                            <h3 className={`320px:text-[14px] 414px:text-[16px] 768px:text-[13px] 991px:text-[18px] 1024px:text-[18px] font-bold ${pricingData.plan_id === selectedPlan ? 'text-black' : 'text-[#8b8b8c]'}`}>
                            {pricingData.label}<span className="text-sm block">${pricingData.price}</span></h3>
                          </div>

                        <div className={`w-[100px] gap-1 flex right-0 absolute ${pricingData.plan_id === selectedPlan ? 'text-black' : 'text-[#8b8b8c]'}`}>
                          $
                          <h1 className="text-5xl flex w-[30px]">
                            0
                          </h1>   

                          <div className="flex flex-col min-w-[55px] ">
                            <div className="plan_cents flex">
                            {(() => {
                              if (pricingData.plan_id === topPlanId) {
                                return  "67";
                              } else if (pricingData.plan_id === mostPopPlanId) {
                                return  "44";
                              } else if (pricingData.plan_id === defaultPlanId) {
                                return  "22";
                              } else {
                                return null;
                              }
                            })()}
                            </div>
                            <div className="plan_day text-[0.65rem] font-semibold flex">
                            PER DAY
                            </div>
                          </div>
                        </div>      
                        </div>
                      </div>)
                      } )}
                      
                      <button
                      className="bg-[#177755] text-white font-bold py-5 px-6 rounded-xl w-full my-4 inline-block justify-between items-center text-center mx-auto drop-shadow-lg transition-colors hover:bg-[#0b422f] transform active:scale-90"
                                onClick={() => {
                                  redirectToPaymentPage(parseInt(selectedPlan))}}
                              >
                        <span className='text-xl inline-block'>Continue  </span>
                        <img
          src={ctaArrow}
          alt="Chatbot Ask"
          className="absolute inline-block block dark:hidden top-[38%] pl-[18%]"
        />
                      </button>
                    </div>
                    
                    <div className="securecont flex pb-5 flex-col justify-center items-center">
                      <div className="text-sm text-center flex bg-[#f2f9f5] text-[#177755] gap-2 w-[173px] h-[40px] justify-center items-center rounded-[8px] mb-2"> 
                            <svg width="15" height="18" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_107_368)">
                                <path d="M13.9271 4.96469C13.9189 4.53271 13.9111 4.12463 13.9111 3.72983C13.9111 3.5813 13.852 3.43885 13.747 3.33383C13.642 3.2288 13.4995 3.16979 13.351 3.16979C10.9559 3.16979 9.13234 2.48147 7.61231 1.00355C7.50779 0.901866 7.36771 0.844971 7.22189 0.844971C7.07606 0.844971 6.93599 0.901866 6.83146 1.00355C5.31159 2.48147 3.48832 3.16979 1.09336 3.16979C0.944826 3.16979 0.802377 3.2288 0.697349 3.33383C0.592321 3.43885 0.533317 3.5813 0.533317 3.72983C0.533317 4.1247 0.525551 4.53293 0.517262 4.96499C0.440499 8.98503 0.335361 14.4907 7.03846 16.8141C7.15725 16.8553 7.28645 16.8553 7.40524 16.8141C14.1089 14.4906 14.0039 8.98481 13.9271 4.96469ZM7.22192 15.6907C1.47314 13.6023 1.56006 9.02752 1.6372 4.98634C1.64182 4.74381 1.64631 4.50874 1.64944 4.2783C3.89259 4.18362 5.68637 3.50388 7.22192 2.1662C8.75763 3.50388 10.5517 4.18369 12.795 4.2783C12.7981 4.50866 12.8026 4.74358 12.8073 4.98597C12.8843 9.0273 12.9712 13.6022 7.22192 15.6907Z" fill="#177755"/>
                                <path d="M9.14967 6.89957L6.44662 9.60248L5.29316 8.44902C5.18753 8.34691 5.04601 8.29039 4.89911 8.29164C4.7522 8.29289 4.61166 8.3518 4.50779 8.45569C4.40391 8.55958 4.345 8.70012 4.34377 8.84703C4.34253 8.99393 4.39907 9.13544 4.50118 9.24106L6.05063 10.7905C6.10258 10.8426 6.16431 10.8839 6.23226 10.912C6.30022 10.9402 6.37307 10.9546 6.44662 10.9545C6.52017 10.9546 6.59302 10.9402 6.66097 10.912C6.72893 10.8839 6.79065 10.8426 6.8426 10.7905L9.94157 7.69162C9.99359 7.63963 10.0349 7.5779 10.063 7.50996C10.0912 7.44201 10.1057 7.36919 10.1057 7.29564C10.1057 7.2221 10.0912 7.14927 10.0631 7.08133C10.0349 7.01338 9.99366 6.95164 9.94165 6.89964C9.83663 6.79462 9.6942 6.73561 9.54568 6.7356C9.39715 6.73558 9.25471 6.79456 9.14967 6.89957Z" fill="#177755"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_107_368">
                                  <rect width="14" height="17" fill="white" transform="translate(0.5 0.5)"/>
                                </clipPath>
                              </defs>
                            </svg>  Pay safe & secure
                          </div>

                        <div className="securelogo mb-2 text-sm text-center w-full flex flex-wrap justify-center items-center w-full">
                          <img src={ccImages} alt="Secure Logo" className="cclogo inline text-center w-[265px]"/>
                        </div>
                      </div>
                  </div>
          </div>
            
          </div>
        </div>
      </section>
    </div>
  );
}
