import React, { useState, useRef, useEffect } from 'react';
import HeaderDropdown from './HeaderDropdown';
import { showPricing } from '~/components/helper/global';
import { usePricingPopup, subscriptionType, setWindowValue } from '~/components/helper/store';
import { FaUser } from "react-icons/fa6";
import Settings from './HeaderSettings';


export default function Header({ showThemeModal }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const pState = usePricingPopup((state) => state);
  const proState = subscriptionType((state) => state)

  const toggleDropdown = () => {
    if (isDropdownOpen) {
      setIsDropdownVisible(false);
      setTimeout(() => setIsDropdownOpen(false), 200);
    } else {
      setIsDropdownOpen(true);
      setTimeout(() => setIsDropdownVisible(true), 10);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownVisible(false);
      setTimeout(() => setIsDropdownOpen(false), 200);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="hidden absolute top-0 left-0 right-0 md:flex items-center justify-between dark:bg-[#1A1D22] border-b border-gray-300/10 dark:border-b dark:border-gray-900/90 py-2 px-4 z-50">
      <div className="flex items-center">
        <img
          src="/assets/chatbot-ask-logo-icon.svg"
          alt="Chatbot Ask"
          className="block dark:hidden"
        />
        <img
          src="/assets/chatbot-ask-logo-icon.svg"
          alt="Chatbot Ask"
          className="hidden dark:block"
        />
        <span className="ml-2 text-[26px] font-bold text-gray-900 dark:text-white">ChatbotAsk</span>
      </div>

      <div className="flex items-center relative">
        {(!proState.isPro && !proState.isProMax) && (
          <button
            className="go_pro_btn px-4 py-1.5 rounded hidden items-center mr-4 md:flex"
            style={{
              background: 'linear-gradient(to bottom, #FFC123 0%, #E3A400 100%)',
              color: 'white',
            }}
            onClick={() => {
              setWindowValue('mxclickLocation', 'Go-Pro-Button')
              showPricing(() => {
                pState.setShowPricingPopup(true);
              });
            }}
            title="GO PRO"
            type="button"
          >
            <span className="mr-2 font-medium">GO PRO</span>
            <img src="/assets/gopro.svg" alt="Go Pro" className="block" />
          </button>
        )}
        <button
          onClick={toggleDropdown}
          className="bg-[#015288] text-[white] focus:outline-none text-xl font-bold rounded-full w-[45px] h-[45px] flex items-center justify-center relative"
        >
        {AIPRO_USER.email ? <FaUser size={20} role="img" aria-label="User"/> : 'G'}
        </button>

        {isDropdownOpen && (
          <div ref={dropdownRef}>
            <HeaderDropdown isVisible={isDropdownVisible} setIsDropdownVisible={setIsDropdownVisible} setShowSettings={setShowSettings} showThemeModal={showThemeModal} />
          </div>
        )}
      </div>
      {showSettings && <Settings open={showSettings} onOpenChange={setShowSettings} />}
    </header>
  );
}
